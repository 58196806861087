
//@ts-expect-error
import { modalCard } from "aml";
import { RegistrationService } from "@/services";
import { defineComponent } from "vue";
import { RegistrationDocumentVM, RegistrationSearchItem } from "@/models";
// @ts-expect-error 
import { readOnlyGrid, renderIconCell } from "hestia";
import { GridColumnProps } from "@progress/kendo-vue-grid";
import { downloadFile, getFileIcon } from "@/helpers";
import { RegistrationStatus } from "@/enums";

export default defineComponent({
  components: {
    modalCard,
    readOnlyGrid,
  },
  props: {
    registration: {
      type: RegistrationSearchItem,
      require: true,
    },
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  emits: ["modalClosed"],
  inject: ["api"],
  data() {
    return {
      registrationService: new RegistrationService(this.api),
      docs: [] as RegistrationDocumentVM[],
      isLoading: false,
      columns: [
        { field: "documentName", title: "Document Name", filter: "text" },
        {
          field: "link",
          cell: renderIconCell(this.getIcon),
          title: "Download",
          filterable: false,
          width: "90px",
          className: 'has-text-centered',
          hidden: () => this.areFilesArchived
        },
      ] as GridColumnProps[],
    };
  },
  async beforeMount() {
    await this.loadDocs();
  },
  methods: {
    async onCloseClick() {
      this.$emit("modalClosed");
    },
    async loadDocs() {
      if (this.registration?.registrationId) {
        this.isLoading = true;
        try {
          this.docs = await this.registrationService.getRegistrationDocuments(this.registration.registrationId);
        } finally {
          this.isLoading = false;
        }
      }
    },
    async onCellClicked(event: any) {
      if (event.field === "link") {
        let response;
        try {
          response = await this.api.getFileResponse(event.dataItem.documentName, event.dataItem.referenceName);
        } catch (error) {
          this.$errorModal(error);
        }
        const blob = await response.blob();
        downloadFile(blob, event.dataItem.documentName);
      }
    },
    getIcon(event: any): string {
      return `${getFileIcon(event.dataItem.documentName)} doc-modal-icon`;
    },
  },
  computed: {
    areFilesArchived(): boolean {
      return this.registration?.registrationStatusId === RegistrationStatus.ARCHIVED;
    },
  }
});
