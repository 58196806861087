
import { defineComponent, markRaw, PropType } from "vue";
import { RegistrationSearchCriteriaPM, RegistrationSearchItem, SubmitRenewalPM } from "@/models";
import { RegistrationRenewalDialog } from "@/components";
// @ts-expect-error 
import { readOnlyGrid, renderCellWithRoute, toggleButton } from "hestia";
import { GridColumnProps, GridSortChangeEvent } from "@progress/kendo-vue-grid";
import { RegistrationService } from "@/services";
import DocCell from "./docs-cell.vue";
import RenewActionCell from "./renew-action-cell.vue";
import DocsModal from "./docs-modal.vue";
import { RegistrationStatus } from "@/enums";
import { ExcelExportOptions }  from "@progress/kendo-vue-excel-export";

export default defineComponent({
  name: "RegistrationSearch",
  components: {
    readOnlyGrid,
    toggleButton,
    DocsModal,
    RegistrationRenewalDialog,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
    registrations: {
      type: Array as PropType<RegistrationSearchItem[]>,
    },
    currentCriteria: {
      type: Object as PropType<RegistrationSearchCriteriaPM>,
      default: {}
    }
  },
  inject: ["api", "userAuth"],
  emits: ["registrationRenewed", "excelExportClicked"],
  data() {
    return {
      isDocModalVisible: false,
      isDocModalRendered: false,
      isRenewalDialogVisible: false,
      registrationService: new RegistrationService(this.api),
      renewalComments: "",
      registration: new RegistrationSearchItem(),
      columns: this.getGridColumns(),
      gridState: null as any,
      includeQuotesAndDebitAuthsCols: false
    };
  },
  mounted() {
    this.initGridState();
  },
  computed: {
    excelExportSettings(): ExcelExportOptions {
      return {
        data: this.registrations,
        fileName: "export.xlsx",
        columns: [
          { field: "registrationNumber", title: "Registration Number" },
          { field: "distributorName", title: "Distributor Name" },
          { field: "customerName", title: "Customer Name" },
          { field: "countryName", title: "Country" },
          { field: "catalogPart", title: "Catalog Part" },
          { field: "projectName", title: "Project Name" },
          { field: "price", title: "Price" },
          { field: "resale", title: "Resale" },
          { field: "designValue", title: "Design Value" },
          {
            field: "registrationStatusName",
            title: "Registration Status Name",
          },
          {
            field: "expirationDate",
            title: "Expiration Date",
            format: "{0:d-MMM-y}",
          },
          { field: "hasDocs", title: "Has Docs" },
          { field: "assignedToDisplayName", title: "Assigned To DisplayName" },
          { field: "distributorInternalReference", title: "Distributor Internal Reference" },
          { field: "latestQuoteId", title: "Latest QuoteID", hidden: !this.includeQuotesAndDebitAuthsCols },
          { field: "latestDebitAuthId", title: "Latest DebitAuthID", hidden: !this.includeQuotesAndDebitAuthsCols }
        ],
      } as ExcelExportOptions;
    },
  },
  methods: {
    /** Gets the route to registrationDetails page for the provided registrationItem */
    getRegistrationRoute(dataItem: any): string {
      return `/registrations/details/${dataItem.registrationNumber}`;
    },

    getGridColumns(): GridColumnProps[] {
       const columns = [
        {
          field: "registrationNumber",
          title: "Registration",
          filter: "text",
          cell: renderCellWithRoute(this.getRegistrationRoute, "_blank"),
        },
        { field: "distributorName", title: "Distributor", filter: "text" },
        { field: "customerName", title: "Customer", filter: "text" },
        { field: "catalogPart", title: "Part", filter: "text" },
        { field: "price", title: "Price ($)", filterable: false, width: "85px" },
        { field: "resale", title: "Resale ($)", filterable: false, width: "85px" },
        { field: "designValue", title: "Design Value ($)", filterable: "numeric", width: "125px", format: "{0:##,#}" },
        { field: "registrationStatusName", title: "Status", filter: "text", width: "100px" },
        { field: "expirationDate", title: "Expiry", format: "{0:d-MMM-y}" },
        { cell: markRaw(DocCell), field: "hasDocs", title: "Docs", filter: "boolean", width: "75px", className: 'has-text-centered' },
        { cell: markRaw(RenewActionCell), field: "actions", title: "Actions", filterable: false, width: "100px" }
      ] as GridColumnProps[];

      // if allegroUser add also column for regAssignee
      if (this.userAuth.isAllegro) {
        columns.push({ field: "assignedToDisplayName", title: "Assigned To", filter: "text"});
      }

      return columns;
    },

    /** On searchResult cell click check which cell is clicked: `hasDocs` - opens the dialog with attachments, `actions` - open Renew dialog */
    onCellClicked(event: any) {
      this.registration = event.dataItem;
      if (event.field === "hasDocs" && event.dataItem.hasDocs) {
        this.isDocModalVisible = true;
        this.isDocModalRendered = true;
      } else if (event.field === "actions" && this.registration.canModify && this.isRenewableRegistration(this.registration)) {
        // if the registration is not in status `Pre-Exp` or user does not have permission to modify, do not show modal.
        this.isRenewalDialogVisible = true;
      }
    },

    onDocModalClose() {
      this.isDocModalVisible = false;
      this.$closeModal(() => this.isDocModalRendered = false);
    },

    submitRenewal(): Promise<void> {
      const renewal = new SubmitRenewalPM(
        this.registration.registrationId,
        this.renewalComments,
        this.registration.registrationRowVersion
      );
      return this.registrationService.submitRenewal(renewal);
    },

    async resolveRenewalDialogAction(proceed: boolean) {
      this.isRenewalDialogVisible = false;

      if (proceed) {
        try {
          await this.submitRenewal();
          // notify parent reload the searchResults to reflects the latest changes
          this.$emit("registrationRenewed");
        } catch (error) {
          this.$errorModal(error);
        }
      }
    },

    isRenewableRegistration(registration: RegistrationSearchItem): boolean {
      return registration.registrationStatusId === RegistrationStatus.PREEXP;
    },

    async updateUrlQuery(criteria: any) {
      const current = { ...this.$route.query };
      const updated = Object.assign(current, criteria);
      await this.$router.replace({ path: this.$route.path, query: updated });
    },

    sortChanged(event: GridSortChangeEvent) {
      this.updateUrlQuery({ sort: JSON.stringify(event.sort) });
    },

    pageChanged(event: any) {
      const { skip, take } = event.page;
      this.updateUrlQuery({ skip, take });
    },

    filterChanged(event: any) {
      this.updateUrlQuery({ filter: JSON.stringify(event.filter) });
    },

    initGridState() {
      const { sort, skip, take, filter } = this.$route.query;
      try {
        this.gridState = {
          sort: sort ? JSON.parse(sort as string) : null,
          skip: skip ? parseInt(skip as string) : null,
          take: take ? parseInt(take as string) : null,
          filter: filter ? JSON.parse(filter as string) : null
        };
      } catch (error) {
        this.$toast({
          message: "Error while initializing grid state from url query",
          type: "danger"
        });
        this.gridState = null;
      }
    },
    async customExportFn() {
      this.$emit("excelExportClicked", this.excelExportSettings, this.includeQuotesAndDebitAuthsCols);
    }
  }
});
