
import { RegistrationStatus } from "@/enums";
import { RegistrationSearchItem } from "@/models";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  props: {
    dataItem: Object as PropType<RegistrationSearchItem>,
  },
  computed: {
    isRenewable(): boolean {
      return !!(this.dataItem?.canModify && this.dataItem?.registrationStatusId === RegistrationStatus.PREEXP);
    },
  },
});
