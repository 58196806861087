import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggleButton = _resolveComponent("toggleButton")
  const _component_read_only_grid = _resolveComponent("read-only-grid")
  const _component_docs_modal = _resolveComponent("docs-modal")
  const _component_registration_renewal_dialog = _resolveComponent("registration-renewal-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_toggleButton, {
        modelValue: _ctx.includeQuotesAndDebitAuthsCols,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.includeQuotesAndDebitAuthsCols = $event)),
        classAlways: "is-light h5 mb-3",
        iconOff: "far fa-square",
        iconOn: "fas fa-check-square",
        titleOn: "Enriched export",
        titleOff: "Enriched export",
        title: "Add Quotes and Debit Authorization references to exported data"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_read_only_grid, {
        gridColumns: _ctx.columns,
        gridItems: _ctx.registrations,
        isLoading: _ctx.isLoading,
        pageItemsOptions: [10, 20, 50],
        gridState: _ctx.gridState,
        excelExportOptions: _ctx.excelExportSettings,
        customExportFn: _ctx.customExportFn,
        onCellClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onCellClicked($event))),
        onSortChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sortChanged($event))),
        onPageChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.pageChanged($event))),
        onFilterChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.filterChanged($event)))
      }, null, 8, ["gridColumns", "gridItems", "isLoading", "gridState", "excelExportOptions", "customExportFn"])
    ]),
    (_ctx.isDocModalRendered)
      ? (_openBlock(), _createBlock(_component_docs_modal, {
          key: _ctx.registration.registrationId,
          isVisible: _ctx.isDocModalVisible,
          registration: _ctx.registration,
          onModalClosed: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onDocModalClose()))
        }, null, 8, ["isVisible", "registration"]))
      : _createCommentVNode("", true),
    _createVNode(_component_registration_renewal_dialog, {
      active: _ctx.isRenewalDialogVisible,
      modelValue: _ctx.renewalComments,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.renewalComments = $event)),
      registrationNumber: _ctx.registration.registrationNumber,
      onResolveDialogAction: _cache[8] || (_cache[8] = ($event: any) => (_ctx.resolveRenewalDialogAction($event)))
    }, null, 8, ["active", "modelValue", "registrationNumber"])
  ], 64))
}