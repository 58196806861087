
import { defineComponent } from "vue";
import { RegistrationSearchCriteriaPM, RegistrationSearchItem } from "@/models";
import { RegistrationService } from "@/services";
import RegistrationResult from "./registration-result.vue";
import SearchBox from "./search-box.vue";
import { ExcelExportOptions } from "@progress/kendo-vue-excel-export";
import { saveExcel } from "@progress/kendo-vue-excel-export";
// @ts-expect-error
import { sanitizeDataForExcel } from "hestia";

export default defineComponent({
  name: "RegistrationSearch",
  components: {
    RegistrationResult,
    SearchBox
  },
  inject: ["api"],
  data() {
    return {
      registrations: [] as RegistrationSearchItem[],
      isSearchLoading: false,
      registrationService: new RegistrationService(this.api),
      searchCriteria: {} as RegistrationSearchCriteriaPM
    };
  },
  methods: {
    onSearchClick(criteria: RegistrationSearchCriteriaPM) {
      this.searchCriteria = criteria;
      this.searchRegistrations();
    },

    async searchRegistrations() {
      this.isSearchLoading = true;
      try {
        this.registrations = await this.registrationService.searchRegistration(this.searchCriteria);
      } 
      catch(error) {
        this.$errorModal(error);
      }
      finally {
        this.isSearchLoading = false;
      }
    },
    async customExportFn(excelExportSettings: ExcelExportOptions, includeQuotesAndDebitAuthsCols: boolean) {
      this.$hardLoading();
      try {
        excelExportSettings.data = await this.registrationService.searchRegistration(this.searchCriteria, includeQuotesAndDebitAuthsCols);
        // We need to sanitize the data manaully if not using Hestia default export
        excelExportSettings.data = sanitizeDataForExcel(excelExportSettings.data);
        saveExcel(excelExportSettings);
      }
      catch(error) {
        this.$errorModal(error);
      }
      finally {
        this.$doneLoading();
      }
    }
  },
});
