
import { defineComponent } from "vue";
// @ts-expect-error
import { pageSearchBox } from "hestia";
import { RegistrationSearchCriteriaPM, RegistrationSearchItem, RegistrationStatusVM } from "@/models";
//@ts-expect-error
import _ from "lodash";
import { RegistrationStatusService } from "@/services";

export default defineComponent({
  components: {
    pageSearchBox,
  },
  inject: ["api"],
  emits: ["registrationSearched"],
  data() {
    return {
      registrations: [] as RegistrationSearchItem[],
      currentCriteria: {} as RegistrationSearchCriteriaPM,
      searchFromNavBar: "",
      countries: [] as any[],
      regStatuses: [] as RegistrationStatusVM[],
      searchKeys: RegistrationSearchCriteriaPM.getRegistrationSearchOptions(),
      regStatusService: new RegistrationStatusService(this.api)
    };
  },
  methods: {
    async onSearch(criteria: any) {
      await this.updateUrlQuery(criteria);
      this.currentCriteria = new RegistrationSearchCriteriaPM(criteria, this.countries, this.regStatuses);
      this.$emit("registrationSearched", this.currentCriteria);
    },

    async updateUrlQuery(criteria: any) {
      await this.$router.replace({ path: this.$route.path, query: criteria });
    },

    /** Maps the initially provided searchObjects to key:value pair to be displayed in searchBox. */
    updateInitialSearchInput(searchObject: any) {
      this.searchFromNavBar = Object.entries(searchObject)
          // translate into kebabCase for search widget
        .map(a => `${_.kebabCase(a[0])}: ${a[1]}`)
        .join(" ");
    },
  },

  async created() {
    // Separate searchObject and discard grid related properties
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const { skip, take, sort, filter, ...searchObject} = this.$route.query;
    const isSearchObjectProvided = searchObject && Object.keys(searchObject).length !== 0;
    if (isSearchObjectProvided) {
      this.updateInitialSearchInput(searchObject);
    }

    // load static data
    [this.countries, this.regStatuses] = await Promise.all([
      this.api.getCountries(),
      this.regStatusService.getStatuses()
    ]);

    // add countryList and regStatuses 
    RegistrationSearchCriteriaPM.addCountrySearchCriterion(this.searchKeys, this.countries);
    RegistrationSearchCriteriaPM.addRegStatusSearchCriterion(this.searchKeys, this.regStatuses);

    // if there is initialSearchObject, then search
    if (isSearchObjectProvided) {
      this.currentCriteria = new RegistrationSearchCriteriaPM(searchObject, this.countries, this.regStatuses);
      this.$emit("registrationSearched", this.currentCriteria);
    }
  },
});
