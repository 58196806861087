import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_search_box = _resolveComponent("page-search-box")

  return (_openBlock(), _createBlock(_component_page_search_box, {
    providedSearchValue: _ctx.searchFromNavBar,
    searchCriteria: _ctx.searchKeys,
    onSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSearch($event)))
  }, null, 8, ["providedSearchValue", "searchCriteria"]))
}